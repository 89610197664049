/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import { perf2Store } from '~/modules/SDK/Perf/perf2Store';
const sounds = useSoundStore.getState();
export const morerich_bandtrade60_enduring = createIndicator({
    id: 'morerichbandtrade60enduring',
    displayName: '長波聚寶/趨勢王者',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
            perf2Store.reset();
        },
        main(context, inputCallback) {
            const isCheckDay = this.isSettlementDay();
            let triggerTradeAction = false;
            let triggerExitPrice = 0;
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const high_array = this._context.new_var(high);
            const daily_high = this._context.new_var();
            const low = this.PineJS.Std.low(this._context);
            const low_array = this._context.new_var(low);
            const daily_low = this._context.new_var();
            const close = this.PineJS.Std.close(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const start_w = new Date(itime).getDay();
            const pc_high = this._context.new_var();
            const pc_low = this._context.new_var();
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const bDt = this._context.new_var();
            const bExitSound = this._context.new_var();
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            var pv0 = NaN;
            var pv1 = NaN;
            var pvflag = this._context.new_var();
            var entryPrice = this._context.new_var();
            var pb1 = NaN;
            var pb2 = NaN;
            var pbx = NaN;
            var ps1 = NaN;
            var ps2 = NaN;
            var psx = NaN;
            //Input-------------------------------------------------------------------
            /** 多停損 */
            const input0_stopLoss = this._input(0);
            /** 多停利 */
            const input1_target = this._input(1);
            /** 空停損 */
            const input2_stopLoss = this._input(2);
            /** 空停利 */
            const input3_target = this._input(3);
            /** 停利單 */
            const input_datum = this._input(4);
            /** 百分停利 */
            const input_percentage = this._input(5);
            /** 多空控制 */
            const setPosition = this._input(6);
            //Entry High Entry Low-------------------------------------------------------------------
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            if (start_h === 8 && start_m === 45 && start_w === 3) {
                daily_high.set(high);
                daily_low.set(low);
                bsentry.set(0);
                bDt.set(itime);
            }
            if (high > daily_high) {
                daily_high.set(high);
            }
            if (low < daily_low) {
                daily_low.set(low);
            }
            if (start_h === 12 && start_m === 45 && start_w === 3) {
                pc_high.set(daily_high * 1.005);
                pc_low.set(daily_low * 0.995);
            }
            bState.get(1);
            bState.get(2);
            entryPrice.get(0);
            entryPrice.get(1);
            bExitSound.get(1);
            low_array.get(1);
            high_array.get(1);
            entryHigh.get(1);
            entryLow.get(1);
            /** 部位確立 */
            if (start_h > 8) {
                if (setPosition !== -1 &&
                    close > pc_high &&
                    bsentry.get(0) <= 1 &&
                    itime - bDt.get(0) > 120 * 60 * 1000) {
                    bState.set(1);
                    bDt.set(itime);
                }
                else if (setPosition !== 1 &&
                    close < pc_low &&
                    bsentry.get(0) <= 1 &&
                    itime - bDt.get(0) > 120 * 60 * 1000) {
                    bState.set(-1);
                    bDt.set(itime);
                }
            }
            /** 訊號出訊至圖表 */
            if (bState.get(0) === 1 && bState.get(1) !== 1 && bsentry.get(0) <= 5) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                bsentry.set(bsentry + 1);
                entryPrice.set(close);
                entryHigh.set(high);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1 && bsentry.get(0) <= 5) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                bsentry.set(bsentry + 1);
                entryPrice.set(close);
                entryLow.set(low);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            var p1 = 0.005;
            var p2 = 0.01;
            //多方出場-------------------------------------------------------------------
            //月結算
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                //月結算
                if (isCheckDay === true) {
                    bState.set(0);
                    pb1 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                    bDt.set(itime);
                }
                //停損價
                if (low < entryPrice.get(0) - input0_stopLoss) {
                    bState.set(0);
                    pbx = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = Math.round(open < entryPrice.get(0) - input0_stopLoss ? open : entryPrice.get(0) - input0_stopLoss);
                    bDt.set(itime);
                }
                if (pvflag.get(0) === 0 && high > entryPrice.get(0) + input1_target / 2) {
                    pvflag.set(1);
                    pb1 = 1;
                }
                //百分比停利
                const long_trailing_price = Math.round(entryHigh - (input_percentage / 100) * (entryHigh - entryPrice));
                if (entryHigh > entryPrice + input_datum && low < long_trailing_price) {
                    pvflag.set(2);
                    pb2 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice = long_trailing_price;
                }
            }
            //空方出場-------------------------------------------------------------------
            if (bState.get(0) === -1 && bState.get(1) === -1) {
                //月結算
                if (isCheckDay === true) {
                    bState.set(0);
                    ps1 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //停損價
                if (high > entryPrice.get(0) + input2_stopLoss) {
                    bState.set(0);
                    psx = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = Math.round(open > entryPrice.get(0) + input2_stopLoss ? open : entryPrice.get(0) + input2_stopLoss);
                    bDt.set(itime);
                }
                if (pvflag.get(0) === 0 && low < entryPrice.get(0) - input3_target / 2) {
                    ps1 = 1;
                    pvflag.set(1);
                }
                //百分比停利
                const short_traling_price = Math.round(entryLow + (input_percentage / 100) * (entryPrice - entryLow));
                if (entryLow < entryPrice - input_datum && high > short_traling_price) {
                    pvflag.set(2);
                    ps2 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice = short_traling_price;
                }
            }
            //對翻
            if (bState.get(0) === 1 && bState.get(1) === -1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            if (bState.get(0) === -1 && bState.get(1) === 1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            //serializeDataString-------------------------------------------------------------------
            const serializeDataString = (marketposition, Exmarketposition, priceEntry, priceExit, ExpriceEntry) => {
                /** 多單出場 */
                const longExit = marketposition === 0 && Exmarketposition === 1;
                /** 空單出場 */
                const shortExit = marketposition === 0 && Exmarketposition === -1;
                /** 空翻多 空單出場 */
                const longRetroflexion = marketposition === 1 && Exmarketposition === -1;
                /** 多翻空 多單出場 */
                const shortRetroflexion = marketposition === -1 && Exmarketposition === 1;
                /** 多單平倉點數邏輯 */
                const longExitProfit = priceExit - priceEntry;
                /** 空單平倉點數邏輯 */
                const shortExitProfit = priceEntry - priceExit;
                /** 空翻多單平倉點數邏輯 */
                const longRetroflexionProfit = ExpriceEntry - priceEntry;
                /** 多翻空單平倉點數邏輯 */
                const shortRetroflexionProfit = priceExit - ExpriceEntry;
                /** 正常出場事件 */
                const defaultExit = longExit || shortExit;
                /** 翻單出場事件 */
                const retroflexionExit = longRetroflexion || shortRetroflexion;
                const defaultProfit = longExit ? longExitProfit : shortExitProfit;
                const retroflexionProfit = shortRetroflexion
                    ? shortRetroflexionProfit
                    : longRetroflexionProfit;
                const newEntryProfit = 0;
                const profit = (defaultExit ? defaultProfit : retroflexionExit ? retroflexionProfit : newEntryProfit) *
                    200;
                const price = marketposition !== 0 ? priceEntry : priceExit;
                return [itime, marketposition, price, profit].join('_');
            };
            //isBarChanging-------------------------------------------------------------------
            const localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            // TODO: isBarChanging 抽出去，價格的數字會變超大，找另一個方式來解決抽出來問題
            if (triggerTradeAction && isBarChanging) {
                const data_key = [symbol, resolution, itime].join('_');
                const data_str = serializeDataString(bState.get(0), bState.get(1), entryPrice.get(0), triggerExitPrice, entryPrice.get(1));
                sessionStorage.setItem(data_key, data_str);
                sessionStorage.setItem('currentTVChartSymbol', symbol || '');
                sessionStorage.setItem('currentTVChartResolution', resolution);
                sessionStorage.setItem('currentTVChartTimestampMs', itime.toString());
                sessionStorage.setItem('currentTVChartLastModified', new Date().getTime().toString());
                perf2Store.addTrade({
                    symbol: symbol || '',
                    interval: resolution,
                    openAt: itime,
                    bar0: {
                        position: bState.get(0),
                        priceEntry: entryPrice,
                        priceExit: triggerExitPrice,
                    },
                    bar1: {
                        position: bState.get(1),
                    },
                });
            }
            return [
                this.PineJS.Std.sma(pc_high, 1, this._context),
                this.PineJS.Std.sma(pc_low, 1, this._context),
                pv0,
                pv1,
                pb1,
                pb2,
                NaN,
                ps1,
                ps2,
                NaN,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 90,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 90,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_2: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#00bb00',
                    textColor: '#00aa00',
                    transparency: 20,
                    visible: true,
                },
                plot_4: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 70,
                    visible: true,
                },
                plot_5: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 70,
                    visible: true,
                },
                plot_7: {
                    color: '#0aaaaa',
                    textColor: '#0aaaaa',
                    transparency: 70,
                    visible: true,
                },
                plot_8: {
                    color: '#0aaaaa',
                    textColor: '#0aaaaa',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#0aaa55',
                    textColor: '#0aaa55',
                    transparency: 70,
                    visible: true,
                },
            },
            inputs: {
                in_0: 80,
                in_1: 150,
                in_2: 80,
                in_3: 150,
                in_4: 250,
                in_5: 15,
                in_6: 0,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'shapes',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'shapes',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_xcross',
                size: 'small',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_xcross',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '多單停損點數',
                defval: 80,
                type: 'integer',
                min: 0,
                max: 10000,
            },
            { id: 'in_1', name: '多單停利點數', defval: 150, type: 'integer', min: 0, max: 10000 },
            {
                id: 'in_2',
                name: '空單停損點數',
                defval: 80,
                type: 'integer',
                min: 0,
                max: 10000,
            },
            { id: 'in_3', name: '空單停利點數', defval: 150, type: 'integer', min: 0, max: 10000 },
            { id: 'in_4', name: '百分比拉回基準點', defval: 250, type: 'integer', min: 1, max: 999 },
            { id: 'in_5', name: '百分比拉回', defval: 15, type: 'integer', min: 10, max: 90 },
            {
                id: 'in_6',
                name: '部位選擇:多[1] 空[-1] 多空[0]',
                defval: 0,
                type: 'integer',
                min: -1,
                max: 1,
            },
        ],
        scriptIdPart: '',
    },
});
