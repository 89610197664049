/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useIndicatorStore } from '~/store/useIndicatorStore';
export const morerich_daytrade = createIndicator({
    id: 'morerichdaytrade',
    displayName: '當沖',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            let triggerTradeAction = false;
            let triggerExitPrice = 0;
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            const state = useIndicatorStore.getState();
            const high = this.PineJS.Std.high(this._context);
            const high_array = this._context.new_var(high);
            const daily_high = this._context.new_var();
            const low = this.PineJS.Std.low(this._context);
            const low_array = this._context.new_var(low);
            const daily_low = this._context.new_var();
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const pc_high = this._context.new_var();
            const pc_low = this._context.new_var();
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const bDt = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const bExitSound = this._context.new_var();
            const sar0_p0 = 0.026; /*this._input(0)*/
            const sar1_p0 = 0.1; /*this._input(1)*/
            var pv0 = NaN;
            var pv1 = NaN;
            var pvflag = this._context.new_var();
            var entryPrice = this._context.new_var();
            var arwPrice = this._context.new_var();
            var pb1 = NaN;
            var pb2 = NaN;
            var pbx = NaN;
            var ps1 = NaN;
            var ps2 = NaN;
            var psx = NaN;
            //0845 重新計算開盤區間---------------------
            if (start_h === 8 && start_m === 45) {
                daily_high.set(high);
                daily_low.set(low);
                bsentry.set(0);
                bState.set(0);
            }
            //0900 停止計算開盤區間---------------------
            if (start_h === 9 && start_m === 0) {
                pc_high.set(daily_high * 1);
                pc_low.set(daily_low * 1);
            }
            //1530 停止計算夜盤區間---------------------
            if (start_h === 15 && start_m === 30) {
                pc_high.set(daily_high * 1);
                pc_low.set(daily_low * 1);
                bsentry.set(0);
            }
            if (high > daily_high) {
                daily_high.set(high);
            }
            if (low < daily_low) {
                daily_low.set(low);
            }
            if (start_h === 8) {
                pc_high.set(NaN);
                pc_low.set(NaN);
            }
            bState.get(1);
            bState.get(2);
            entryPrice.get(1);
            bExitSound.get(1);
            bExitSound.get(2);
            close_array.get(1);
            low_array.get(1);
            high_array.get(1);
            entryHigh.get(1);
            entryLow.get(1);
            /** 停損 */
            const input_stopLoss_buy = this._input(0);
            const input_stopLoss_short = this._input(1);
            /** 停利單 */
            const input_target1 = this._input(2);
            /** Sar */
            const input_sar_buy = this._input(3);
            /** Sarmax */
            const input_sarmax_buy = this._input(4);
            /** Sar */
            const input_sar_short = this._input(5);
            /** Sarmax */
            const input_sarmax_short = this._input(6);
            /** 交易次數 */
            const input_trader_count = this._input(7);
            /** 多空參數控制 */
            const setPosition = this._input(8);
            const input_sar_start_buy = this._input(9);
            const input_sar_start_short = this._input(10);
            const sar_value_buy = this.PineJS.Std.sar(input_sar_start_buy, input_sar_buy, input_sarmax_buy, this._context);
            const sar_value_array_buy = this._context.new_var(sar_value_buy);
            const sar_value_short = this.PineJS.Std.sar(input_sar_start_short, input_sar_short, input_sarmax_short, this._context);
            const sar_value_array_short = this._context.new_var(sar_value_short);
            sar_value_array_buy.get(1);
            sar_value_array_short.get(1);
            //日盤出手限制次數=4----------------------------
            /** 部位確立 */
            if (start_h < 13 || start_h > 15) {
                if (setPosition !== -1 &&
                    close > pc_high &&
                    close > sar_value_buy &&
                    //close_array.get(1) < pc_high &&
                    bsentry.get(0) < input_trader_count) {
                    bState.set(1);
                    bDt.set(itime);
                    arwPrice.set(close);
                }
                else if (setPosition !== 1 &&
                    close < pc_low &&
                    close < sar_value_short &&
                    //close_array.get(1) > pc_low &&
                    bsentry.get(0) < input_trader_count) {
                    bState.set(-1);
                    bDt.set(itime);
                    arwPrice.set(itime);
                }
            }
            //Entry High Entry Low-------------------------------------------------------------------
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            //如果15:30突破 要出現進場訊號 前一根K棒bState必須=0,強制歸0
            //否則第300行的bState會持續set 1 or -1
            //造成15:30無訊號
            if (start_h === 15 && start_m === 29) {
                bState.set(0);
            }
            //部位進場與成立訊號-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1 && bsentry.get(0) < input_trader_count) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                bExitSound.set(0);
                bsentry.set(bsentry + 1);
                entryHigh.set(high);
                entryPrice.set(close);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            else if (bState.get(0) === -1 &&
                bState.get(1) !== -1 &&
                bsentry.get(0) < input_trader_count) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                bExitSound.set(0);
                bsentry.set(bsentry + 1);
                entryLow.set(low);
                entryPrice.set(close);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            let pbsarx;
            let pssarx;
            //多方出場-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                //劃出SAR
                pbsarx = sar_value_buy;
                pssarx = NaN;
                //間間出場
                if (start_h === 13 && start_m === 30) {
                    bState.set(0);
                    pb2 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //停損價
                if (low < entryPrice - input_stopLoss_buy) {
                    bState.set(0);
                    pbx = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = entryPrice - input_stopLoss_buy;
                }
                //固定停利
                if (pvflag.get(0) === 0 && high > entryPrice + input_target1) {
                    pvflag.set(1);
                    pb1 = 1;
                }
                // const long_target_price = entryPrice.get(0) + input_target1
                // const long_trailing_price = entryHigh - (input_percentage / 100) * (entryHigh - entryPrice)
                // if (entryHigh > entryPrice + input_datum && low < long_trailing_price) {
                //   pvflag.set(2)
                //   pb2 = 1
                //   bState.set(0)
                //   triggerTradeAction = true
                //   //若一次打到兩個出場條件 以最接近進場價的當作出場價
                //   triggerExitPrice =
                //     long_target_price < long_trailing_price ? long_target_price : long_trailing_price
                // }
                if (low < sar_value_buy) {
                    pb2 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = Math.round(sar_value_array_buy.get(1));
                }
            }
            //空方出場-------------------------------------------------------------------
            if (bState.get(0) === -1 && bState.get(1) === -1) {
                //劃出SAR
                pbsarx = NaN;
                pssarx = sar_value_short;
                //時間出場
                if (start_h === 13 && start_m === 30) {
                    bState.set(0);
                    ps2 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //停損價
                if (high > entryPrice + input_stopLoss_short) {
                    bState.set(0);
                    psx = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = entryPrice + input_stopLoss_short;
                }
                //固定停利
                if (pvflag.get(0) === 0 && low < entryPrice - input_target1) {
                    pvflag.set(1);
                    ps1 = 1;
                }
                // //百分比停利
                // const short_target_price = entryPrice.get(0) - input_target1
                // const short_traling_price = entryLow + (input_percentage / 100) * (entryPrice - entryLow)
                // if (entryLow < entryPrice - input_datum && high > short_traling_price) {
                //   console.log(entryLow.get(0), short_target_price, 123)
                //   pvflag.set(2)
                //   ps2 = 1
                //   bState.set(0)
                //   triggerTradeAction = true
                //   //若一次打到兩個出場條件 以最接近進場價的當作出場價
                //   triggerExitPrice =
                //     short_target_price > short_traling_price ? short_target_price : short_traling_price
                // }
                if (high > sar_value_short) {
                    ps2 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = Math.round(sar_value_array_short.get(1));
                }
            }
            //對翻-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) === -1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            if (bState.get(0) === -1 && bState.get(1) === 1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            //serializeDataString-------------------------------------------------------------------
            const serializeDataString = (marketposition, Exmarketposition, priceEntry, priceExit, ExpriceEntry) => {
                /** 多單出場 */
                const longExit = marketposition === 0 && Exmarketposition === 1;
                /** 空單出場 */
                const shortExit = marketposition === 0 && Exmarketposition === -1;
                /** 空翻多 空單出場 */
                const longRetroflexion = marketposition === 1 && Exmarketposition === -1;
                /** 多翻空 多單出場 */
                const shortRetroflexion = marketposition === -1 && Exmarketposition === 1;
                /** 多單平倉點數邏輯 */
                const longExitProfit = priceExit - priceEntry;
                /** 空單平倉點數邏輯 */
                const shortExitProfit = priceEntry - priceExit;
                /** 空翻多單平倉點數邏輯 */
                const longRetroflexionProfit = ExpriceEntry - priceEntry;
                /** 多翻空單平倉點數邏輯 */
                const shortRetroflexionProfit = priceExit - ExpriceEntry;
                /** 正常出場事件 */
                const defaultExit = longExit || shortExit;
                /** 翻單出場事件 */
                const retroflexionExit = longRetroflexion || shortRetroflexion;
                const defaultProfit = longExit ? longExitProfit : shortExitProfit;
                const retroflexionProfit = shortRetroflexion
                    ? shortRetroflexionProfit
                    : longRetroflexionProfit;
                const newEntryProfit = 0;
                const profit = (defaultExit ? defaultProfit : retroflexionExit ? retroflexionProfit : newEntryProfit) *
                    200;
                const price = marketposition !== 0 ? priceEntry : priceExit;
                return [itime, marketposition, price, profit].join('_');
            };
            if (triggerTradeAction) {
                const data_key = [symbol, resolution, itime].join('_');
                const data_str = serializeDataString(bState.get(0), bState.get(1), entryPrice.get(0), triggerExitPrice, entryPrice.get(1));
                sessionStorage.setItem(data_key, data_str);
                sessionStorage.setItem('currentTVChartSymbol', symbol || '');
                sessionStorage.setItem('currentTVChartResolution', resolution);
                sessionStorage.setItem('currentTVChartTimestampMs', itime.toString());
                sessionStorage.setItem('currentTVChartLastModified', new Date().getTime().toString());
            }
            return [
                sar_value_array_buy.get(0),
                sar_value_array_short.get(0),
                pv0,
                pv1,
                pb1,
                pb2,
                NaN,
                ps1,
                ps2,
                NaN,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 90,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 90,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_2: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#00bb00',
                    textColor: '#00aa00',
                    transparency: 20,
                    visible: true,
                },
                plot_4: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 70,
                    visible: true,
                },
                plot_5: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 70,
                    visible: true,
                },
                plot_7: {
                    color: '#0aaaaa',
                    textColor: '#0aaaaa',
                    transparency: 70,
                    visible: true,
                },
                plot_8: {
                    color: '#0aaaaa',
                    textColor: '#0aaaaa',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#0aaa55',
                    textColor: '#0aaa55',
                    transparency: 70,
                    visible: true,
                },
            },
            inputs: {
                in_0: 40,
                in_1: 50,
                in_2: 75,
                in_3: 0.001,
                in_4: 0.019,
                in_5: 0.001,
                in_6: 0.001,
                in_7: 2,
                in_8: 0,
                in_9: 0.003,
                in_10: 0.02,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'shapes',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'shapes',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                title: 'Shapes',
                text: 'B',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                title: 'Shapes',
                text: 'S',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_xcross',
                size: 'small',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_xcross',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [
            { id: 'in_0', name: '多單停損', defval: 40, type: 'integer', min: 1, max: 999 },
            { id: 'in_1', name: '停損停損', defval: 50, type: 'integer', min: 1, max: 999 },
            {
                id: 'in_2',
                name: '短單(純顯示不納入績效)',
                defval: 75,
                type: 'integer',
                min: 1,
                max: 999,
            },
            { id: 'in_3', name: '多sar加速', defval: 0.005, type: 'float', min: 0.001, max: 0.9 },
            { id: 'in_4', name: '多sar最大', defval: 0.019, type: 'float', min: 0.001, max: 0.9 },
            { id: 'in_5', name: '空sar加速', defval: 0.001, type: 'float', min: 0.001, max: 0.9 },
            { id: 'in_6', name: '空sar最大', defval: 0.001, type: 'float', min: 0.001, max: 0.9 },
            { id: 'in_7', name: '日交易次數限制', defval: 2, type: 'integer', min: 1, max: 5 },
            {
                id: 'in_8',
                name: '部位選擇:多[1] 空[-1] 多空[0]',
                defval: 0,
                type: 'integer',
                min: -1,
                max: 1,
            },
            { id: 'in_9', name: '多sar起始', defval: 0.003, type: 'float', min: 0.001, max: 0.9 },
            { id: 'in_10', name: '空sar起始', defval: 0.02, type: 'float', min: 0.001, max: 0.9 },
        ],
        scriptIdPart: '',
    },
});
