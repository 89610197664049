import { max } from 'lodash';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const morerich_strategy3 = createIndicator({
    id: 'morerichstrategy3',
    displayName: '策略3-macd',
    constructorScope: {
        init(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;
            ////usePerfStore.getState().tradesClean()
            return;
        },
        main(context, inputCallback) {
            /** ema1 */
            const input0 = this._input(0);
            /** ema2 */
            const input1 = this._input(1);
            /*state.parameter_1 = [
              { name: '短均線週期', value: this._input(0) },
              { name: '長均線週期', value: this._input(1) },
            ]*/
            /** macd */
            const input2 = this._input(2);
            /** 停利 */
            const input2_stopLoss = this._input(3);
            const input3_target = this._input(4);
            /** 百分停利門檻 */
            const input4_datum = this._input(5);
            /** 百分停利 */
            const input5_percentage = this._input(6);
            /** 多空參數控制 */
            const setPosition = this._input(7);
            const isCheckDay = this.isSettlementDay();
            //商品數值 開高低收 時間
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close = this.ohlc.close;
            const itime = this.PineJS.Std.time(this._context);
            //商品數值 開高低收Array
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const close_array = this.ohlc.closeArray;
            //部位相關 狀態
            const bState = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryTime = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const exitPrice = this._context.new_var();
            //macd
            const fast = this.PineJS.Std.ema(close_array, input0, this._context);
            const slow = this.PineJS.Std.ema(close_array, input1, this._context);
            const dif = fast - slow;
            const dif_array = this._context.new_var(dif);
            const dem = this.PineJS.Std.ema(dif_array, input2, this._context);
            const osc = dif - dem;
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            //部位相關 買賣、停利訊號
            let pv0 = NaN;
            let pv1 = NaN;
            let stoploss0 = NaN;
            let target0 = NaN;
            let percent0 = NaN;
            let stoploss1 = NaN;
            let target1 = NaN;
            let percent1 = NaN;
            bState.get(1);
            low_array.get(1);
            high_array.get(1);
            entryPrice.get(1);
            const serializeDataString = (marketposition, Exmarketposition, price) => {
                const longExit = marketposition !== 1 && Exmarketposition === 1;
                const shortExit = marketposition !== -1 && Exmarketposition === -1;
                const longExitProfit = close - price;
                const shortExitProfit = price - close;
                const newEntryProfit = 0;
                const profit = (longExit ? longExitProfit : shortExit ? shortExitProfit : newEntryProfit) * 200;
                return [itime, marketposition, close, profit].join('_');
            };
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            const buy_condition = dif_array.get(0) > dif_array.get(1) &&
                dif_array.get(1) > dif_array.get(2) &&
                dif_array.get(2) > dif_array.get(3) &&
                dif_array.get(3) > dif_array.get(4) &&
                dif_array.get(4) > dif_array.get(5);
            const short_condition = null;
            //部位進場--------------------------------------------
            if ((setPosition === 1 && buy_condition) || (setPosition === 0 && buy_condition)) {
                bState.set(1);
                entryTime.set(itime);
            }
            else if ((setPosition === -1 && short_condition) ||
                (setPosition === 0 && short_condition)) {
                bState.set(-1);
                entryTime.set(itime);
            }
            let triggerTradeAction = false;
            //部位進場--------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                entryPrice.set(close);
                entryHigh.set(high);
                triggerTradeAction = true;
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                entryPrice.set(close);
                entryLow.set(low);
                triggerTradeAction = true;
            }
            const difHighest = max([
                dif_array.get(1),
                dif_array.get(2),
                dif_array.get(3),
                dif_array.get(4),
                dif_array.get(5),
            ]) ?? 0;
            //停損設置
            if (bState.get(0) === 1 && bState.get(1) === 1 && low < entryPrice.get(0) - input2_stopLoss) {
                stoploss0 = 1;
                bState.set(0);
                triggerTradeAction = true;
            }
            else if (bState.get(0) === 1 &&
                bState.get(1) === 1 &&
                low < entryPrice.get(0) &&
                dif > difHighest === false) {
                stoploss0 = 1;
                bState.set(0);
                triggerTradeAction = true;
            }
            else if (bState.get(0) === -1 &&
                bState.get(1) === -1 &&
                high > entryPrice.get(0) + input2_stopLoss) {
                stoploss1 = 1;
                bState.set(0);
                triggerTradeAction = true;
            }
            //出場設置
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                //月結算出場
                if (isCheckDay === true) {
                    bState.set(0);
                    target0 = 1;
                    triggerTradeAction = true;
                }
                //固定停利
                if (high > entryPrice + input3_target) {
                    target0 = 1;
                    bState.set(0);
                    exitPrice.set(entryPrice.get(1) + input3_target);
                    triggerTradeAction = true;
                }
                if (bState.get(0) === 1 &&
                    bState.get(1) === 1 &&
                    low > entryPrice.get(0) &&
                    dif > difHighest === false) {
                    target0 = 1;
                    bState.set(0);
                    exitPrice.set(entryPrice.get(1) + input3_target);
                    triggerTradeAction = true;
                }
                if (entryHigh > entryPrice + input4_datum &&
                    close < entryHigh - (input5_percentage / 100) * (entryHigh - entryPrice)) {
                    percent0 = 1;
                    bState.set(0);
                    exitPrice.set(entryHigh - (input5_percentage / 100) * (entryHigh - entryPrice));
                    triggerTradeAction = true;
                }
            }
            else if (bState.get(0) === -1 && bState.get(1) === -1) {
                //月結算出場
                if (isCheckDay === true) {
                    bState.set(0);
                    target1 = 1;
                    triggerTradeAction = true;
                }
                //固定停利
                if (low < entryPrice - input3_target) {
                    target1 = 1;
                    bState.set(0);
                    exitPrice.set(entryPrice.get(1) - input3_target);
                    triggerTradeAction = true;
                }
                //百分比停利
                if (entryLow < entryPrice - input4_datum &&
                    close > entryLow + (input5_percentage / 100) * (entryPrice - entryLow)) {
                    percent1 = 1;
                    bState.set(0);
                    exitPrice.set(entryLow + (input5_percentage / 100) * (entryPrice - entryLow));
                    triggerTradeAction = true;
                }
            }
            if (triggerTradeAction) {
                const data_key = [symbol, resolution, itime].join('_');
                const data_str = serializeDataString(bState.get(0), bState.get(1), entryPrice.get(1));
                sessionStorage.setItem(data_key, data_str);
                sessionStorage.setItem('currentTVChartSymbol', symbol || '');
                sessionStorage.setItem('currentTVChartResolution', resolution);
                sessionStorage.setItem('currentTVChartTimestampMs', itime.toString());
                sessionStorage.setItem('currentTVChartLastModified', new Date().getTime().toString());
            }
            return [pv0, pv1, NaN, percent0, target0, NaN, percent1, target1];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_4: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_6: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_7: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_8: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_9: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_10: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_11: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {
                in_0: 200,
                in_1: 201,
                in_2: 202,
                in_3: 999,
                in_4: 999,
                in_5: 200,
                in_6: 50,
                in_7: 0,
            },
        },
        plots: [
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
            {
                id: 'plot_10',
                type: 'chars',
            },
            {
                id: 'plot_11',
                type: 'chars',
            },
        ],
        styles: {
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '×',
                size: 'small',
            },
            plot_7: {
                isHidden: false,
                location: 'AboveBar',
                char: '✱',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'AboveBar',
                char: '×',
                size: 'small',
            },
            plot_10: {
                isHidden: false,
                location: 'BelowBar',
                char: '✱',
                size: 'small',
                title: 'Shapes',
            },
            plot_11: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [
            { id: 'in_0', name: 'ema1', defval: 200, type: 'integer', min: 1, max: 10000 },
            { id: 'in_1', name: 'ema2', defval: 201, type: 'integer', min: 1, max: 10000 },
            { id: 'in_2', name: 'macd', defval: 202, type: 'integer', min: 1, max: 10000 },
            { id: 'in_3', name: '停損點數', defval: 999, type: 'integer', min: 0, max: 10000 },
            { id: 'in_4', name: '停利點數', defval: 999, type: 'integer', min: 0, max: 10000 },
            { id: 'in_5', name: '百分比拉回基準點', defval: 200, type: 'integer', min: 0, max: 10000 },
            { id: 'in_6', name: '百分比拉回', defval: 50, type: 'integer', min: 0, max: 100 },
            {
                id: 'in_7',
                name: '部位選擇:多[1] 空[-1] 多空[0]',
                defval: 0,
                type: 'integer',
                min: -1,
                max: 1,
            },
        ],
        scriptIdPart: '',
    },
});
