import { staticrcStore } from '~/configs/staticrcStore';
/**
 * ### 現在可用 `fr_instrument.getBigPointValue('2330')`
 *
 * # @deprecated
 *
 * 返回每點價值
 *
 * - E.g. 小台每點 50 新台幣
 * - E.g. 大台每點 200 新台幣
 * - E.g. 股票每點 1000 新台幣
 *
 * @deprecated
 */
export const getBigPointValue = (symbol) => {
    if (staticrcStore.contractInfo[symbol]) {
        return staticrcStore.contractInfo[symbol].big_point_value;
    }
    /** E.g. `0050` `0051` */
    if (symbol.includes('TX')) {
        return 200;
    }
    /** E.g. `0050` `0051` */
    if (symbol.match(/[\d]{4}/)) {
        return 1000;
    }
    return 50;
};
