import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const morerich_daytrade2 = createIndicator({
    displayName: '當沖逆',
    id: 'morerichdaytrade2',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            let triggerTradeAction = false;
            let triggerExitPrice = 0;
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            const isCheckDay = this.isSettlementDay();
            const len = this._input(0);
            const k_len = this._input(1);
            const d_len = this._input(2);
            const volume = this.PineJS.Std.volume(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const open_array = this._context.new_var(open);
            const volume_array = this._context.new_var(volume);
            const stoch = this.PineJS.Std.stoch(close_array, high_array, low_array, len, this._context);
            const stoch_array = this._context.new_var(stoch);
            //高
            const vHn = this.PineJS.Std.highest(high_array, len, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, len, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            const vrsv_array = this._context.new_var(vrsv);
            //K%
            const vk = this.PineJS.Std.ema(vrsv_array, k_len, this._context);
            const vk_array = this._context.new_var(vk);
            //D%
            const vd = this.PineJS.Std.ema(vk_array, d_len, this._context);
            //部位
            const bState = this._context.new_var();
            const bDt = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryTime = this._context.new_var();
            const bsentry = this._context.new_var();
            const dayHigh = this._context.new_var();
            const dayLow = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_stoploss_icon = NaN;
            let buy_target0 = NaN;
            let buy_target1 = NaN;
            let short_stoploss_icon = NaN;
            let short_target0 = NaN;
            let short_target1 = NaN;
            /** 停損 */
            const input1_stopLoss = this._input(0);
            /** 停利 */
            const input2_target = this._input(1);
            /** 成交量倍數 */
            const input3_volumestd = this._input(2);
            /** 多空參數控制 */
            const setPosition = this._input(3);
            /** 交易次數 */
            const input_trader_count = this._input(4);
            /** 停利單 */
            const input_datum = this._input(5);
            /** 百分停利 */
            const input_percentage = this._input(6);
            //當日高與低
            if (start_h === 8 && start_m === 45) {
                dayHigh.set(high);
                dayLow.set(low);
                bsentry.set(0);
            }
            if (high > dayHigh) {
                dayHigh.set(high);
            }
            if (low < dayLow) {
                dayLow.set(low);
            }
            //Entry High Entry Low-------------------------------------------------------------------
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            bState.get(1);
            entryPrice.get(1);
            volume_array.get(1);
            open_array.get(1);
            close_array.get(1);
            low_array.get(1);
            high_array.get(1);
            entryPrice.get(1);
            entryHigh.get(1);
            entryLow.get(1);
            const basic_condiction = start_h >= 9 &&
                start_h < 13 &&
                (start_h === 9 ? start_m >= 30 : start_m >= 0) &&
                volume < volume_array.get(1) &&
                volume_array.get(1) / volume >= input3_volumestd &&
                bsentry.get(0) < input_trader_count &&
                dayHigh.get(0) - dayLow.get(0) > 100;
            const buy_condition = basic_condiction &&
                low_array.get(1) === dayLow.get(0) &&
                low > low_array.get(1) &&
                close > open &&
                close_array.get(1) < open_array.get(1);
            const short_condition = basic_condiction &&
                high_array.get(1) === dayHigh.get(0) &&
                high < high_array.get(1) &&
                close < open &&
                close_array.get(1) > open_array.get(1);
            if (start_h === 15 && start_m === 29) {
                bState.set(0);
            }
            if ((setPosition === 1 && buy_condition) || (setPosition === 0 && buy_condition)) {
                bState.set(1);
                entryTime.set(itime);
            }
            else if ((setPosition === -1 && short_condition) ||
                (setPosition === 0 && short_condition)) {
                bState.set(-1);
                entryTime.set(itime);
            }
            //部位進場--------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                buy_icon = 1;
                short_icon = NaN;
                bsentry.set(bsentry + 1);
                pvflag.set(0);
                entryPrice.set(close);
                entryHigh.set(high);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                buy_icon = NaN;
                short_icon = 1;
                bsentry.set(bsentry + 1);
                pvflag.set(0);
                entryPrice.set(close);
                entryLow.set(low);
                triggerTradeAction = true;
                triggerExitPrice = close;
            }
            //多方出場-------------------------------------------------------------------
            //時間出場
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                //停損設置
                if (start_h === 13 && start_m === 30) {
                    bState.set(0);
                    buy_stoploss_icon = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //停損價
                if (low < entryPrice.get(0) - input1_stopLoss) {
                    buy_stoploss_icon = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = entryPrice.get(0) - input1_stopLoss;
                }
                //第一階段停利
                if (pvflag.get(0) === 0 && entryHigh > entryPrice.get(0) + input2_target) {
                    buy_target0 = 1;
                    pvflag.set(1);
                }
                const long_trailing_price = entryHigh - (input_percentage / 100) * (entryHigh - entryPrice);
                if (pvflag.get(0) === 1 &&
                    entryHigh > entryPrice + input_datum &&
                    low < long_trailing_price) {
                    pvflag.set(2);
                    buy_target1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice = long_trailing_price;
                }
            }
            //空方出場-------------------------------------------------------------------
            //時間出場
            if (bState.get(0) === -1 && bState.get(1) === -1) {
                //時間出場
                if (start_h === 13 && start_m === 30) {
                    bState.set(0);
                    short_stoploss_icon = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //停損價
                if (high > entryPrice.get(0) + input1_stopLoss) {
                    short_stoploss_icon = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = entryPrice.get(0) + input1_stopLoss;
                }
                //第一階段停利
                if (pvflag.get(0) === 0 && entryLow < entryPrice.get(0) - input2_target) {
                    short_target0 = 1;
                    pvflag.set(1);
                }
                //百分比停利
                const short_traling_price = entryLow + (input_percentage / 100) * (entryPrice - entryLow);
                if (pvflag.get(0) === 1 &&
                    entryLow < entryPrice - input_datum &&
                    high > short_traling_price) {
                    pvflag.set(2);
                    short_target1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice = short_traling_price;
                }
            }
            //對翻-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) === -1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            if (bState.get(0) === -1 && bState.get(1) === 1) {
                triggerTradeAction = true;
                triggerExitPrice = close;
                entryPrice.set(close);
                bDt.set(itime);
            }
            //serializeDataString-------------------------------------------------------------------
            const serializeDataString = (marketposition, Exmarketposition, priceEntry, priceExit, ExpriceEntry) => {
                /** 多單出場 */
                const longExit = marketposition === 0 && Exmarketposition === 1;
                /** 空單出場 */
                const shortExit = marketposition === 0 && Exmarketposition === -1;
                /** 空翻多 空單出場 */
                const longRetroflexion = marketposition === 1 && Exmarketposition === -1;
                /** 多翻空 多單出場 */
                const shortRetroflexion = marketposition === -1 && Exmarketposition === 1;
                /** 多單平倉點數邏輯 */
                const longExitProfit = priceExit - priceEntry;
                /** 空單平倉點數邏輯 */
                const shortExitProfit = priceEntry - priceExit;
                /** 空翻多單平倉點數邏輯 */
                const longRetroflexionProfit = ExpriceEntry - priceEntry;
                /** 多翻空單平倉點數邏輯 */
                const shortRetroflexionProfit = priceExit - ExpriceEntry;
                /** 正常出場事件 */
                const defaultExit = longExit || shortExit;
                /** 翻單出場事件 */
                const retroflexionExit = longRetroflexion || shortRetroflexion;
                const defaultProfit = longExit ? longExitProfit : shortExitProfit;
                const retroflexionProfit = shortRetroflexion
                    ? shortRetroflexionProfit
                    : longRetroflexionProfit;
                const newEntryProfit = 0;
                const profit = (defaultExit ? defaultProfit : retroflexionExit ? retroflexionProfit : newEntryProfit) *
                    200;
                const price = marketposition !== 0 ? priceEntry : priceExit;
                return [itime, marketposition, price, profit].join('_');
            };
            if (triggerTradeAction) {
                const data_key = [symbol, resolution, itime].join('_');
                const data_str = serializeDataString(bState.get(0), bState.get(1), entryPrice.get(0), triggerExitPrice, entryPrice.get(1));
                sessionStorage.setItem(data_key, data_str);
                sessionStorage.setItem('currentTVChartSymbol', symbol || '');
                sessionStorage.setItem('currentTVChartResolution', resolution);
                sessionStorage.setItem('currentTVChartTimestampMs', itime.toString());
                sessionStorage.setItem('currentTVChartLastModified', new Date().getTime().toString());
            }
            return [
                NaN,
                buy_icon,
                short_icon,
                NaN,
                buy_target0,
                buy_target1,
                NaN,
                short_target0,
                short_target1,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#26c6da',
                },
                plot_1: { color: '#ff1744', textColor: '#ff1744', transparency: 0, visible: true },
                plot_2: { color: '#2196f3', textColor: '#2196f3', transparency: 0, visible: true },
                plot_3: { color: '#ff1744', textColor: '#ff1744', transparency: 80, visible: true },
                plot_4: { color: '#ff1744', textColor: '#ff1744', transparency: 60, visible: true },
                plot_5: { color: '#ff1744', textColor: '#ff1744', transparency: 0, visible: true },
                plot_6: { color: '#2196f3', textColor: '#2196f3', transparency: 80, visible: true },
                plot_7: { color: '#2196f3', textColor: '#2196f3', transparency: 60, visible: true },
                plot_8: { color: '#2196f3', textColor: '#2196f3', transparency: 0, visible: true },
            },
            precision: 4,
            inputs: {
                in_0: 35,
                in_1: 20,
                in_2: 1.1,
                in_3: 0,
                in_4: 2,
                in_5: 40,
                in_6: 1,
            },
        },
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'chars' },
            { id: 'plot_2', type: 'chars' },
            { id: 'plot_3', type: 'chars' },
            { id: 'plot_4', type: 'chars' },
            { id: 'plot_5', type: 'chars' },
            { id: 'plot_6', type: 'chars' },
            { id: 'plot_7', type: 'chars' },
            { id: 'plot_8', type: 'chars' },
        ],
        styles: {
            plot_0: { title: '均線', histogramBase: 0, joinPoints: !1 },
            plot_1: { isHidden: false, location: 'BelowBar', char: '▲', size: 'small', text: 'B' },
            plot_2: { isHidden: false, location: 'AboveBar', char: '▼', size: 'small', text: 'S' },
            plot_3: { isHidden: false, location: 'BelowBar', char: '×', size: 'small' },
            plot_4: { isHidden: false, location: 'AboveBar', char: '★', size: 'small', title: 'Shapes' },
            plot_5: { isHidden: false, location: 'AboveBar', char: '★', size: 'small', title: 'Shapes' },
            plot_6: { isHidden: false, location: 'AboveBar', char: '×', size: 'small' },
            plot_7: { isHidden: false, location: 'BelowBar', char: '★', size: 'small', title: 'Shapes' },
            plot_8: { isHidden: false, location: 'BelowBar', char: '★', size: 'small', title: 'Shapes' },
        },
        is_price_study: !0,
        inputs: [
            { id: 'in_0', name: '停損點數', defval: 35, type: 'integer', min: 1, max: 1e4 },
            { id: 'in_1', name: '停利點數', defval: 20, type: 'integer', min: 0, max: 10000 },
            { id: 'in_2', name: '量倍數', defval: 1.1, type: 'float', min: 0, max: 5 },
            {
                id: 'in_3',
                name: '部位選擇:多[1] 空[-1] 多空[0]',
                defval: 0,
                type: 'integer',
                min: -1,
                max: 1,
            },
            { id: 'in_4', name: '交易次數', defval: 2, type: 'float', min: 1, max: 5 },
            { id: 'in_5', name: '百分比拉回基準點', defval: 40, type: 'integer', min: 1, max: 999 },
            { id: 'in_6', name: '百分比拉回', defval: 1, type: 'integer', min: 0, max: 90 },
        ],
        scriptIdPart: '',
    },
});
