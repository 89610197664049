
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/morerich",
      function () {
        return require("private-next-pages/morerich/index.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/morerich"])
      });
    }
  