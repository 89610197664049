import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const morerich_supertrend = createIndicator({
    displayName: 'supertrend',
    id: 'morerichsupertrend',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = () => {
                const input1 = this._input(0);
                const input2 = this._input(1);
                const atr = this.PineJS.Std.atr(input1, this._context);
                const atrup = this.PineJS.Std.hl2(this._context) + atr * input2;
                const atrdn = this.PineJS.Std.hl2(this._context) - atr * input2;
                const close2 = this.PineJS.Std.close(this._context);
                const close_array = this._context.new_var();
                const vart = this._context.new_var();
                const vari = this._context.new_var();
                const o1 = this.PineJS.Std.max(atr, vart.get(1));
                const o2 = this.PineJS.Std.min(atr, vari.get(1));
                vart.set(this.PineJS.Std.gt(close_array.get(1), vart.get(1)) ? o1 : atrup);
                vari.set(this.PineJS.Std.lt(close_array.get(1), vari.get(1)) ? o2 : atrdn);
                const vare = this._context.new_var();
                const rr = this.PineJS.Std.nz(vare.get(0), 1);
                const rr1 = this.PineJS.Std.lt(close, vart.get(1)) ? -1 : rr;
                const rr2 = this.PineJS.Std.gt(close, vari.get(1));
                vare.set(rr2 ? 1 : rr1);
                return [
                    this.PineJS.Std.eq(vare.get(0), 1) ? vart.get(0) : vari.get(0),
                    this.PineJS.Std.eq(vare.get(0), 1) ? 0 : 1,
                ];
            };
            const i = f_0();
            return [i[0], i[1]];
        },
    },
    metainfo: {
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#000080',
                },
            },
            precision: 4,
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#008000',
                            width: 3,
                            style: 0,
                        },
                        1: {
                            color: '#800000',
                            width: 3,
                            style: 0,
                        },
                    },
                },
            },
            inputs: {
                in_0: 10,
                in_1: 3,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                palette: 'palette_0',
                target: 'plot_0',
                type: 'colorer',
            },
        ],
        is_price_study: !0,
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
                valToIndex: {
                    0: 0,
                    1: 1,
                },
            },
        },
        inputs: [
            {
                id: 'in_0',
                name: 'Length',
                defval: 10,
                type: 'integer',
                min: 1,
                max: 100,
            },
            {
                id: 'in_1',
                name: 'Factor',
                defval: 3,
                type: 'float',
                min: 1,
                max: 100,
            },
        ],
        scriptIdPart: '',
        isCustomIndicator: !0,
    },
});
// this.f_0 = function() {
//       var e = this._input(0),
//           t = this._input(1),
//           i = n.Std.atr(e, this._context),
//           r = (e = n.Std.hl2(this._context) + i * t, i = n.Std.hl2(this._context) - i * t, this._context.new_var(n.Std.close(this._context))),
//           o = (t = this._context.new_var(), n.Std.max(i, t.get(1)));
//       return
//         t.set(n.Std.gt(r.get(1), t.get(1)) ? o : i),
//         i = this._context.new_var(),
//         o = n.Std.min(e, i.get(1)),
//         i.set(n.Std.lt(r.get(1), i.get(1)) ? o : e),
//         e = this._context.new_var(),
//         r = n.Std.nz(e.get(1), 1),
//         r = n.Std.lt(n.Std.close(this._context), t.get(1)) ? -1 : r,
//         e.set(n.Std.gt(n.Std.close(this._context), i.get(1)) ? 1 : r),
//         [t = n.Std.eq(e.get(0), 1) ? t.get(0) : i.get(0), n.Std.eq(e.get(0), 1) ? 0 : 1, 1 === e.get(0) && -1 === e.get(1), -1 === e.get(0) && 1 === e.get(1)]
//   },
//   this.main = function(e, t) {
//       this._context = e, this._input = t;
//       var i = this.f_0();
//       return [i[0], i[1], i[2], i[3]]
//   }
