import { css } from '@emotion/react';
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { InvestmentConsultantSubscriptionCheck } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen';
import { meCheckHandlerAgentWeb } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
import { useMorerichChart } from './modules/useMorerichChart';
import { PerfConnect } from '~/modules/SDK/Perf/PerfConnect';
import { PerfDataBar, PerfDataCharts } from '~/modules/SDK/Perf/PerfDataCharts';
import { PerfDataInfo1 } from '~/modules/SDK/Perf/PerfDataInfo';
import { ChartServerSelect } from '~/modules/SDK/Chart2/ChartServerSelect';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { proxy, useSnapshot } from 'valtio';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { createDesktopCss } from '~/css/createDesktopCss';
/* indicators */
import { morerich_strategy } from '~/trades/indicators/morerich/morerich_strategy';
import { morerich_strategy1 } from '~/trades/indicators/morerich/morerich_strategy1';
import { morerich_strategy2 } from '~/trades/indicators/morerich/morerich_strategy2';
import { morerich_strategy3 } from '~/trades/indicators/morerich/morerich_strategy3';
import { morerich_strategy4 } from '~/trades/indicators/morerich/morerich_strategy4';
import { morerich_kd } from '~/trades/indicators/morerich/morerich_kd';
import { morerich_kbar } from '~/trades/indicators/morerich/morerich_kbar';
import { morerich_kbar15 } from '~/trades/indicators/morerich/morerich_kbar15';
import { morerich_macd } from '~/trades/indicators/morerich/morerich_macd';
import { morerich_bandtrade15 } from '~/trades/indicators/morerich/morerich_bandtrade15';
import { morerich_bandtrade60 } from '~/trades/indicators/morerich/morerich_bandtrade60';
import { morerich_daytrade } from '~/trades/indicators/morerich/morerich_daytrade';
import { morerich_daytrade2 } from '~/trades/indicators/morerich/morerich_daytrade2';
import { morerich_bandtrade60_enduring } from '~/trades/indicators/morerich/morerich_bandtrade60_enduring';
import dayAPI from '~/utils/dayAPI';
const appLayoutTopBarCss = css `
  ${flex.h.crossCenter};
  ${jc.spaceBetween};
  padding: 0 16px;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(56, 160, 194, 1), rgba(44, 99, 156, 1));
`;
const mainChartCss = css `
  flex: 1 1 400px;
  border: 1px solid #aaaaaa;
  border-bottom: 1px solid #b4b4b4;
`;
const sideBarCss = css `
  ${createDesktopCss(css `
      width: 15%;
    `)}

  background-color: #ffffff;
  border-bottom: 1px solid #b4b4b4;
`;
const barChartCss = css `
  flex: 2 0 400px;
  background-color: #ffffff;
  padding: 5px;
`;
const dataChartCss = css `
  flex: 2 0 400px;
  background-color: #ffffff;
  padding: 5px;
`;
const infoChartCss = css `
  flex: 1 0 400px;

  background-color: #ffffff;
  padding: 5px;
`;
const TitleStyled = styled.div `
  ${flex.h.allCenter};
  height: 10%;
  width: 100px;
  border-radius: 5px;
  background-color: #efefef;
  margin: 2.5px;
  border: 1px solid #aaaaaa;
`;
const PerChartStyled = styled.div `
  width: 99%;
  height: 87.5%;
  border-radius: 5px;
  background-color: rgb(244, 244, 244);
  padding: 15px;
`;
const IndicatorsSwitchStyled = styled.div `
  ${flex.h.crossCenter};
  height: 30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  border: 1px solid ${props => (props.onState === true ? '#444444' : '#cccccc')};
  background-color: #eeeeee;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: #dddddd;
  }
`;
const store = proxy({
    strategy: 'strategy0',
});
const Strategy0 = [morerich_strategy, morerich_kbar, morerich_kd]; //黃董正常
const Strategy1 = [morerich_strategy2, morerich_kd]; //黃董+夜盤
const Strategy2 = [morerich_strategy1, morerich_kbar15, morerich_kd]; //黃董15分
const Strategy3 = [morerich_strategy4, morerich_kd]; //黃董kd空
const Strategy4 = [morerich_strategy3, morerich_macd]; //macd
const Strategy5 = [morerich_bandtrade15]; //短波段
const Strategy6 = [morerich_bandtrade60]; //長波段|短波高手
const Strategy7 = [morerich_bandtrade60_enduring]; //波段至尊
const Strategy8 = [morerich_daytrade]; //當沖順勢
const Strategy9 = [morerich_daytrade2]; //當沖逆勢
const allStrategy = [
    {
        symbol: 'TX-1',
        interval: '60',
        indicators: Strategy0,
        displayName: '黃董-日(60)',
        state: 'strategy0',
    },
    {
        symbol: 'TX-1',
        interval: '60',
        indicators: Strategy1,
        displayName: '黃董-全(60)',
        state: 'strategy1',
    },
    {
        symbol: 'TX-1',
        interval: '15',
        indicators: Strategy2,
        displayName: '黃董-全(15)',
        state: 'strategy2',
    },
    {
        symbol: 'TX-1',
        interval: '60',
        indicators: Strategy3,
        displayName: '黃董-KD空(60)',
        state: 'strategy3',
    },
    {
        symbol: 'TX-1',
        interval: '15',
        indicators: Strategy5,
        displayName: '轉折悍將',
        state: 'strategy5',
    },
    {
        symbol: 'TX-1',
        interval: '60',
        indicators: Strategy6,
        displayName: '短波聚財',
        state: 'strategy6',
    },
    {
        symbol: 'TX-1',
        interval: '60',
        indicators: Strategy7,
        displayName: '長波聚寶|趨勢王者',
        state: 'strategy7',
    },
    {
        symbol: 'TXAM-1',
        interval: '3',
        indicators: Strategy8,
        displayName: '順勢當沖',
        state: 'strategy8',
    },
    {
        symbol: 'TXAM-1',
        interval: '3',
        indicators: Strategy9,
        displayName: '逆勢當沖',
        state: 'strategy9',
    },
];
const layout = new LayoutModule();
layout.store.TopAndLeftMain.customCSS = css `
  grid-template-rows: 48px 1fr;
  grid-template-columns: 0 1fr;
  gap: 0;

  ${createMobileCss2(css `
    grid-template-rows: 48px auto;
  `)}

  ${createIPadCss(css `
    grid-template-rows: 48px auto;
  `)}
`;
layout.store.Main.customCSS = css `
  height: auto;
  ${createDesktopCss(css `
    height: 100%;
  `)}
`;
const MorerichNextPage = () => {
    const legitUser = meCheckHandlerAgentWeb.useCheck();
    const state = useSnapshot(store);
    const StrategyBytton = memo(function StrategyButton(props) {
        return (<useMorerichChart.SymbolIndicatorsSwitch symbol={props.symbol ?? 'TX-1'} indicators={props.indicators} render={symbol => (<div onClick={() => {
                    useMorerichChart
                        .getState()
                        .widget?.activeChart()
                        .setResolution(props.interval, function callback() {
                        // noop
                    });
                }}>
            <IndicatorsSwitchStyled onClick={() => (store.strategy = props.state)} onState={state.strategy === props.state}>
              {props.displayName}
            </IndicatorsSwitchStyled>
          </div>)}/>);
    });
    const StrategyButtonGroup = memo(function StrategyButtonGroup() {
        return (<div>
        {allStrategy.map((datum, index) => {
                return (<StrategyBytton key={index} symbol={datum.symbol} interval={datum.interval} displayName={datum.displayName} indicators={datum.indicators} state={datum.state}/>);
            })}
      </div>);
    });
    return (<layout.TopAndLeftMain>
      <InvestmentConsultantSubscriptionCheck show={!legitUser}/>
      <layout.Top>
        <div css={appLayoutTopBarCss}>
          <img css={css `
              width: 50px;
            `} src='morerich/favicon.png'/>
          <UserAvatarAsDialogButton />
        </div>
      </layout.Top>
      <layout.Main>
        {legitUser && (<>
            <PerfConnect></PerfConnect>
            <div css={topMainCss}>
              <div css={mainChartCss}>
                <useMorerichChart.Chart />
              </div>
              <div css={sideBarCss}>
                <ChartServerSelect useChart={useMorerichChart}/>
                <div css={css `
                    ${flex.h.allCenter}
                    gap: 2.5px;
                    font-size: 14px;
                    padding: 0px 5px;
                  `}>
                  <TimeRangeButtom stratDatetime={1609430400}>2021年至今</TimeRangeButtom>
                  <TimeRangeButtom stratDatetime={1640966400}>2022年至今</TimeRangeButtom>
                </div>
                <StrategyButtonGroup />
              </div>
            </div>
            <div css={bottomMainCss}>
              <div css={barChartCss}>
                <TitleStyled>單筆績效</TitleStyled>
                <PerChartStyled>
                  <PerfDataBar hasBrush={false}/>
                </PerChartStyled>
              </div>
              <div css={dataChartCss}>
                <TitleStyled>累積績效</TitleStyled>
                <PerChartStyled>
                  <PerfDataCharts />
                </PerChartStyled>
              </div>
              <div css={infoChartCss}>
                <TitleStyled>績效</TitleStyled>
                <PerChartStyled>
                  <PerfDataInfo1 />
                </PerChartStyled>
              </div>
            </div>
          </>)}
      </layout.Main>
    </layout.TopAndLeftMain>);
};
const bottomMainCss = css `
  ${flex.v.default};
  ${createDesktopCss(flex.h.default)};
  height: 40%;
`;
const topMainCss = css `
  ${flex.v.default};
  ${createDesktopCss(flex.h.default)}
  height: 60%;
`;
export default MorerichNextPage;
const TimeRangeButtom = memo(function a(props) {
    return (<div css={css `
        ${flex.h.allCenter};
        width: 50%;
        background-color: #eeeeee;
        border: 1px solid #333333;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: #dddddd;
        }
        &:active {
          background-color: #5498bb;
        }
      `} onClick={() => {
            // 這裡的 delay 是為了土砲解決 async 問題
            useMorerichChart
                .getState()
                .widget?.activeChart()
                .setVisibleRange({
                from: props.stratDatetime,
                to: dayAPI().toDate().getTime() / 1000,
            });
        }}>
      {props.children}
    </div>);
});
