import { createChartStore } from '~/modules/SDK/Chart2/createChartStore';
import { morerich_strategy } from '~/trades/indicators/morerich/morerich_strategy';
import { morerich_strategy1 } from '~/trades/indicators/morerich/morerich_strategy1';
import { morerich_strategy2 } from '~/trades/indicators/morerich/morerich_strategy2';
import { morerich_strategy3 } from '~/trades/indicators/morerich/morerich_strategy3';
import { morerich_strategy4 } from '~/trades/indicators/morerich/morerich_strategy4';
import { morerich_kd } from '~/trades/indicators/morerich/morerich_kd';
import { morerich_kbar } from '~/trades/indicators/morerich/morerich_kbar';
import { morerich_kbar15 } from '~/trades/indicators/morerich/morerich_kbar15';
import { morerich_macd } from '~/trades/indicators/morerich/morerich_macd';
import { morerich_supertrend } from '~/trades/indicators/morerich/morerich_supertrend';
import { morerich_bandtrade15 } from '~/trades/indicators/morerich/morerich_bandtrade15';
import { morerich_bandtrade60 } from '~/trades/indicators/morerich/morerich_bandtrade60';
import { morerich_daytrade } from '~/trades/indicators/morerich/morerich_daytrade';
import { morerich_bandtrade60_enduring } from '~/trades/indicators/morerich/morerich_bandtrade60_enduring';
import { morerich_daytrade2 } from '~/trades/indicators/morerich/morerich_daytrade2';
const preparedIndicators = [
    morerich_strategy,
    morerich_strategy1,
    morerich_strategy2,
    morerich_strategy4,
    morerich_kd,
    morerich_kbar,
    morerich_kbar15,
    morerich_macd,
    morerich_strategy3,
    morerich_supertrend,
    morerich_bandtrade15,
    morerich_bandtrade60,
    morerich_daytrade,
    morerich_daytrade2,
    morerich_bandtrade60_enduring,
];
export const useMorerichChart = createChartStore({
    preparedCustomIndicators: preparedIndicators,
    defaultsOptions: {
        customIndicators: [morerich_strategy, morerich_kd, morerich_kbar],
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        symbol: 'TX-1',
        interval: 60,
        client_id: '',
        overrides: {
            'mainSeriesProperties.candleStyle.borderDownColor': '#00aa00',
            'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
            'mainSeriesProperties.candleStyle.downColor': '#00aa00',
            'mainSeriesProperties.candleStyle.upColor': '#cc0000',
            'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
            'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
            'mainSeriesProperties.candleStyle.drawBorder': false,
            'mainSeriesProperties.showPriceLine': false,
            'mainSeriesProperties.showCountdown': true,
            'paneProperties.horzGridProperties.style': 1,
            'paneProperties.vertGridProperties.style': 1,
            'paneProperties.topMargin': 15,
            'paneProperties.bottomMargin': 15,
            'paneProperties.vertGridProperties.color': '#444444',
            'paneProperties.horzGridProperties.color': '#444444',
            'paneProperties.background': '#ffffff',
            'timeScale.rightOffset': 0,
            'scalesProperties.fontSize': 14,
            'scalesProperties.textColor': '#333333',
            'scalesProperties.lineColor': '#333333',
        },
    },
});
