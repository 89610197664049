import { max, min } from 'lodash';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const morerich_strategy4 = createIndicator({
    id: 'morerichstrategy4',
    displayName: '黃董策略-KD空',
    constructorScope: {
        init(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;
            return;
        },
        main(context, inputCallback) {
            const isCheckDay = this.isSettlementDay();
            let triggerTradeAction = false;
            let triggerExitPrice = 0;
            //商品數值 開高低收 時間
            const open = this.ohlc.open;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close = this.ohlc.close;
            const itime = this.PineJS.Std.time(this._context);
            //商品數值 開高低收Array
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const close_array = this.ohlc.closeArray;
            //部位相關 狀態
            const bState = this._context.new_var();
            const bDt = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryTime = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const exitPrice = this._context.new_var();
            //小時、分鐘
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const openTime = start_h === 8 && start_m === 45;
            const closeTime = start_h === 13 && start_m === 45;
            const setTime0500 = start_h === 4 && start_m === 0;
            const setTime0845 = start_h === 8 && start_m === 45;
            const setTime0945 = start_h === 9 && start_m === 45;
            const setTime1045 = start_h === 10 && start_m === 45;
            const setTime1145 = start_h === 11 && start_m === 45;
            const setTime1245 = start_h === 12 && start_m === 45;
            const isDayTradeTime = start_h >= 8 && start_h < 12;
            const symbol = context.symbol.info?.ticker;
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            //部位相關 買賣、停利訊號
            let pv0 = NaN;
            let pv1 = NaN;
            let stoploss0 = NaN;
            let target0 = NaN;
            let percent0 = NaN;
            let stoploss1 = NaN;
            let target1 = NaN;
            let percent1 = NaN;
            bState.get(1);
            low_array.get(1);
            high_array.get(1);
            entryPrice.get(1);
            //Input-------------------------------------------------------------------
            /** --- */
            const input0 = this._input(0);
            /** 季線 */
            const input1 = this._input(1);
            /** 停損 */
            const input2_stopLoss = this._input(2);
            /** 停利 */
            const input3_target = this._input(3);
            /** 百分停利門檻 */
            const input4_datum = this._input(4);
            /** 百分停利 */
            const input5_percentage = this._input(5);
            /** 停損 */
            const input6_stopLoss = this._input(6);
            /** 停利 */
            const input7_target = this._input(7);
            /** 百分停利門檻 */
            const input8_datum = this._input(8);
            /** 百分停利 */
            const input9_percentage = this._input(9);
            /** 多空參數控制 */
            const setPosition = this._input(10);
            //均價-------------------------------------------------------------------
            const avgPrice = this._context.new_var();
            const avgPrice1145 = this._context.new_var();
            const avgPrice1245 = this._context.new_var();
            const avgPrice0500 = this._context.new_var();
            const avgPrice0845 = this._context.new_var();
            const avgPrice0945 = this._context.new_var();
            const avgPrice1045 = this._context.new_var();
            if (setTime1145) {
                avgPrice1145.set((low + high) / 2);
            }
            if (setTime1245) {
                avgPrice1245.set((low + high) / 2);
            }
            if (setTime0500) {
                avgPrice0500.set((low + high) / 2);
            }
            if (setTime0845) {
                avgPrice0845.set((low + high) / 2);
            }
            if (setTime0945) {
                avgPrice0945.set((low + high) / 2);
            }
            if (setTime1045) {
                avgPrice1045.set((low + high) / 2);
            }
            //停損價-------------------------------------------------------------------
            const stopLosePriceLong = this._context.new_var();
            const stopLosePriceShort = this._context.new_var();
            const low1145 = this._context.new_var();
            const low1245 = this._context.new_var();
            const low0400 = this._context.new_var();
            const low0845 = this._context.new_var();
            const low0945 = this._context.new_var();
            const low1045 = this._context.new_var();
            const high1145 = this._context.new_var();
            const high1245 = this._context.new_var();
            const high0400 = this._context.new_var();
            const high0845 = this._context.new_var();
            const high0945 = this._context.new_var();
            const high1045 = this._context.new_var();
            if (setTime1145) {
                low1145.set(low);
                high1145.set(high);
            }
            if (setTime1245) {
                low1245.set(low);
                high1245.set(high);
            }
            if (setTime0500) {
                low0400.set(low);
                high0400.set(high);
            }
            if (setTime0845) {
                low0845.set(low);
                high0845.set(high);
            }
            if (setTime0945) {
                low0945.set(low);
                high0945.set(high);
            }
            if (setTime1045) {
                low1045.set(low);
                high1045.set(high);
            }
            if (openTime) {
                stopLosePriceLong.set(min([low1145.get(0), low1245.get(0), low0400.get(0)]) ?? 0);
                stopLosePriceShort.set(max([high1145.get(0), high1245.get(0), high0400.get(0)]) ?? 0);
                avgPrice.set((avgPrice1145 + avgPrice1245 + avgPrice0500) / 3);
            }
            if (setTime0945) {
                stopLosePriceLong.set(min([low1245.get(0), low0400.get(0), low0845.get(0)]) ?? 0);
                stopLosePriceShort.set(max([high1245.get(0), high0400.get(0), high0845.get(0)]) ?? 0);
                avgPrice.set((avgPrice1245 + avgPrice0500 + avgPrice0845) / 3);
            }
            if (setTime1045) {
                stopLosePriceLong.set(min([low0400.get(0), low0845.get(0), low0945.get(0)]) ?? 0);
                stopLosePriceShort.set(max([high0400.get(0), high0845.get(0), high0945.get(0)]) ?? 0);
                avgPrice.set((avgPrice0500 + avgPrice0845 + avgPrice0945) / 3);
            }
            if (setTime1145) {
                stopLosePriceLong.set(min([low0845.get(0), low0945.get(0), low1045.get(0)]) ?? 0);
                stopLosePriceShort.set(max([high0845.get(0), high0945.get(0), high1045.get(0)]) ?? 0);
                avgPrice.set((avgPrice0845 + avgPrice0945 + avgPrice1045) / 3);
            }
            //MA-------------------------------------------------------------------
            const sma = this.PineJS.Std.sma(close_array, 5, this._context);
            const sma_array = this._context.new_var(sma);
            const season_ma = this.PineJS.Std.sma(close_array, input1, this._context);
            const season_ma_array = this._context.new_var(season_ma);
            sma_array.get(0);
            sma_array.get(1);
            sma_array.get(2);
            sma_array.get(3);
            season_ma_array.get(0);
            season_ma_array.get(1);
            season_ma_array.get(2);
            season_ma_array.get(3);
            //KD-------------------------------------------------------------------
            const in0 = 9;
            const in1 = 3;
            const in2 = 3;
            const stoch = this.PineJS.Std.stoch(close_array, high_array, low_array, in0, this._context);
            //高
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            //K%
            const vk_array = this._context.new_var();
            const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(s)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(s);
            }
            //D%
            const vd_array = this._context.new_var();
            const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(q)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(q);
            }
            //Entry High Entry Low-------------------------------------------------------------------
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            //condicators-------------------------------------------------------------------
            const buy_condition = NaN;
            const short_condition = 
            //時間
            isDayTradeTime &&
                //收盤>連三均線
                close_array.get(0) > avgPrice.get(0) &&
                close_array.get(1) > avgPrice.get(0) &&
                close_array.get(2) > avgPrice.get(0) &&
                //收盤<季線
                close_array.get(0) < season_ma_array.get(0) &&
                close_array.get(1) < season_ma_array.get(1) &&
                close_array.get(2) < season_ma_array.get(2) &&
                //K<60
                vk_array.get(0) < input0 &&
                //收黑
                close < open;
            //部位進場-------------------------------------------------------------------
            if ((setPosition === 1 && buy_condition) || (setPosition === 0 && buy_condition)) {
                bState.set(1);
                entryTime.set(itime);
            }
            else if ((setPosition === -1 && short_condition) ||
                (setPosition === 0 && short_condition)) {
                bState.set(-1);
                entryTime.set(itime);
            }
            //部位進場與成立訊號-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                entryPrice.set(close);
                entryHigh.set(high);
                triggerTradeAction = true;
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                entryPrice.set(close);
                entryLow.set(low);
                triggerTradeAction = true;
            }
            //多方出場-------------------------------------------------------------------
            //月結算
            if (bState.get(0) === 1 && bState.get(1) === 1) {
                const long_stoploss_price1 = open < entryPrice.get(0) - input2_stopLoss ? open : entryPrice.get(0) - input2_stopLoss;
                const long_stoploss_price2 = open < stopLosePriceLong.get(0) ? open : stopLosePriceLong.get(0);
                //停損價
                const long_stoploss_condition1 = low < entryPrice.get(0) - input2_stopLoss;
                if (long_stoploss_condition1) {
                    stoploss0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        long_stoploss_price1 > long_stoploss_price2
                            ? long_stoploss_price1
                            : long_stoploss_price2;
                }
                //移動停損線
                const long_stoploss_condition2 = low < stopLosePriceLong && low < entryPrice.get(0);
                if (long_stoploss_condition2) {
                    stoploss0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        long_stoploss_price1 > long_stoploss_price2
                            ? long_stoploss_price1
                            : long_stoploss_price2;
                }
                //移動停利線
                if (low < stopLosePriceLong && low > entryPrice.get(0)) {
                    target0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = long_stoploss_price2;
                }
                //固定停利
                const long_target_price = entryPrice.get(0) + input3_target;
                if (high > long_target_price) {
                    target0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = long_target_price;
                }
                //月結算
                if (isCheckDay === true) {
                    bState.set(0);
                    target0 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //時間停利
                if (start_h === 12 && start_m === 45) {
                    target0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        high > long_target_price
                            ? long_target_price
                            : long_stoploss_condition1
                                ? long_stoploss_price1
                                : long_stoploss_condition2
                                    ? long_stoploss_price2
                                    : close;
                }
                //百分比停利
                const long_trailing_price = entryHigh - (input5_percentage / 100) * (entryHigh - entryPrice);
                if (entryHigh > entryPrice + input4_datum && low < long_trailing_price) {
                    percent0 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice =
                        long_target_price < long_trailing_price ? long_target_price : long_trailing_price;
                }
            }
            //空方出場-------------------------------------------------------------------
            if (bState.get(0) === -1 && bState.get(1) === -1) {
                const short_stoploss_price1 = open > entryPrice.get(0) + input6_stopLoss ? open : entryPrice.get(0) + input6_stopLoss;
                const short_stoploss_price2 = open > stopLosePriceShort.get(0) ? open : stopLosePriceShort.get(0);
                //停損價
                const short_stoploss_condition1 = high > entryPrice.get(0) + input6_stopLoss;
                if (short_stoploss_condition1) {
                    stoploss1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        short_stoploss_price1 < short_stoploss_price2
                            ? short_stoploss_price1
                            : short_stoploss_price2;
                }
                //移動停損線
                const short_stoploss_condition2 = high > stopLosePriceShort && high > entryPrice;
                if (short_stoploss_condition2) {
                    stoploss1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        short_stoploss_price1 < short_stoploss_price2
                            ? short_stoploss_price1
                            : short_stoploss_price2;
                }
                //移動停利線
                if (high > stopLosePriceShort && high < entryPrice) {
                    target1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = short_stoploss_price2;
                }
                //固定停利
                const short_target_price = entryPrice - input7_target;
                if (low < entryPrice - input7_target) {
                    target1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice = short_target_price;
                }
                //月結算出場
                if (isCheckDay === true) {
                    bState.set(0);
                    target1 = 1;
                    triggerTradeAction = true;
                    triggerExitPrice = close;
                }
                //收盤時間
                if (start_h === 12 && start_m === 45) {
                    target1 = 1;
                    bState.set(0);
                    triggerTradeAction = true;
                    triggerExitPrice =
                        low < short_target_price
                            ? short_target_price
                            : short_stoploss_condition1
                                ? short_stoploss_price1
                                : short_stoploss_condition2
                                    ? short_stoploss_price2
                                    : close;
                }
                //百分比停利
                const short_traling_price = entryLow + (input9_percentage / 100) * (entryPrice - entryLow);
                if (entryLow < entryPrice - input8_datum && high > short_traling_price) {
                    percent1 = 1;
                    bState.set(0);
                    exitPrice.set(entryLow + (input9_percentage / 100) * (entryPrice - entryLow));
                    triggerTradeAction = true;
                    //若一次打到兩個出場條件 以最接近進場價的當作出場價
                    triggerExitPrice =
                        short_target_price > short_traling_price ? short_target_price : short_traling_price;
                }
            }
            //對翻
            if (bState.get(0) === 1 && bState.get(1) === -1) {
                triggerTradeAction = true;
                triggerExitPrice = entryPrice.get(0);
            }
            if (bState.get(0) === -1 && bState.get(1) === 1) {
                triggerTradeAction = true;
                triggerExitPrice = entryPrice.get(0);
            }
            //serializeDataString-------------------------------------------------------------------
            const serializeDataString = (marketposition, Exmarketposition, priceEntry, priceExit) => {
                const longExit = marketposition !== 1 && Exmarketposition === 1;
                const shortExit = marketposition !== -1 && Exmarketposition === -1;
                const longExitProfit = priceExit - priceEntry;
                const shortExitProfit = priceEntry - priceExit;
                const newEntryProfit = 0;
                const profit = (longExit ? longExitProfit : shortExit ? shortExitProfit : newEntryProfit) * 200;
                const price = marketposition !== 0 ? priceEntry : priceExit;
                return [itime, marketposition, price, profit].join('_');
            };
            //isBarChanging-------------------------------------------------------------------
            const localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            if (triggerTradeAction && isBarChanging) {
                const data_key = [symbol, resolution, itime].join('_');
                const data_str = serializeDataString(bState.get(0), bState.get(1), entryPrice.get(0), triggerExitPrice);
                sessionStorage.setItem(data_key, data_str);
                sessionStorage.setItem('currentTVChartSymbol', symbol || '');
                sessionStorage.setItem('currentTVChartResolution', resolution);
                sessionStorage.setItem('currentTVChartTimestampMs', itime.toString());
                sessionStorage.setItem('currentTVChartLastModified', new Date().getTime().toString());
            }
            return [
                bState.get(0) === 1 ? stopLosePriceLong.get(0) : NaN,
                bState.get(0) === -1 ? stopLosePriceShort.get(0) : NaN,
                avgPrice.get(0),
                season_ma,
                pv0,
                pv1,
                NaN,
                percent0,
                target0,
                NaN,
                percent1,
                target1,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 2,
                    linewidth: 1,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff3300',
                },
                plot_1: {
                    linestyle: 2,
                    linewidth: 1,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff3300',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#2196f3',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffcc44',
                },
                plot_4: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_6: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_7: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_8: {
                    color: '#ff1744',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_9: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_10: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
                plot_11: {
                    color: '#2196f3',
                    textColor: '#2196f3',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {
                in_0: 60,
                in_1: 60,
                in_2: 999,
                in_3: 50,
                in_4: 250,
                in_5: 60,
                in_6: 999,
                in_7: 50,
                in_8: 250,
                in_9: 60,
                in_10: -1,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
            {
                id: 'plot_10',
                type: 'chars',
            },
            {
                id: 'plot_11',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '×',
                size: 'small',
            },
            plot_7: {
                isHidden: false,
                location: 'AboveBar',
                char: '✱',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'AboveBar',
                char: '×',
                size: 'small',
            },
            plot_10: {
                isHidden: false,
                location: 'BelowBar',
                char: '✱',
                size: 'small',
                title: 'Shapes',
            },
            plot_11: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [
            { id: 'in_0', name: '空單KD門檻', defval: 60, type: 'integer', min: 1, max: 99 },
            { id: 'in_1', name: '季線', defval: 60, type: 'integer', min: 1, max: 10000 },
            { id: 'in_2', name: '多單停損點數', defval: 999, type: 'integer', min: 0, max: 10000 },
            { id: 'in_3', name: '多單停利點數', defval: 50, type: 'integer', min: 0, max: 10000 },
            {
                id: 'in_4',
                name: '多單百分比拉回基準點',
                defval: 250,
                type: 'integer',
                min: 0,
                max: 10000,
            },
            { id: 'in_5', name: '多單百分比拉回', defval: 60, type: 'integer', min: 0, max: 100 },
            { id: 'in_6', name: '空單停損點數', defval: 999, type: 'integer', min: 0, max: 10000 },
            { id: 'in_7', name: '空單停利點數', defval: 50, type: 'integer', min: 0, max: 10000 },
            {
                id: 'in_8',
                name: '空單百分比拉回基準點',
                defval: 250,
                type: 'integer',
                min: 0,
                max: 10000,
            },
            { id: 'in_9', name: '空單百分比拉回', defval: 60, type: 'integer', min: 0, max: 100 },
            {
                id: 'in_10',
                name: '部位選擇:多[1] 空[-1] 多空[0]',
                defval: -1,
                type: 'integer',
                min: -1,
                max: 1,
            },
        ],
        scriptIdPart: '',
    },
});
