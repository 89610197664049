import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const morerich_kd = createIndicator({
    displayName: 'KD',
    id: 'morerichkd',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in0 = 9;
            const in1 = 3;
            const in2 = 3;
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const stoch = this.PineJS.Std.stoch(close_array, high_array, low_array, in0, this._context);
            const stoch_array = this._context.new_var(stoch);
            //高
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            const vrsv_array = this._context.new_var(vrsv);
            //K%
            const vk_array = this._context.new_var();
            const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(s)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(s);
            }
            //D%
            const vd_array = this._context.new_var();
            const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(q)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(q);
            }
            return [vk_array.get(0), vd_array.get(0), vk_array.get(0) - vd_array.get(0)];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                //--
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: !0,
                    color: '#0099ff',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: !0,
                    color: '#ff99ff',
                },
            },
        },
        styles: {
            plot_0: {
                title: 'K',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'D',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
