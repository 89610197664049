import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 多空分界(股) */
export const morerich_kbar = createIndicator({
    displayName: '夜盤無交易',
    id: 'morerichkba',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const interval = this.PineJS.Std.interval(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const condition = start_h >= 15 || start_h < 4;
            const colorIndex = condition ? 0 : NaN;
            return [colorIndex];
        },
    },
    metainfo: {
        plots: [
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            //樣式調整
            inputs: {
                in_0: 23,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#999999',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
